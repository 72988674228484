import { put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes.js';
import { getExistingProductsFailure, getExistingProductsSuccess } from '../../../../actions/getProductsActions.js';
import signedFetch from '../../../apiCalls/util/signedFetch.js';

function* getExistingProducts(action) {
  const orgId = action.payload;
  try {
    const response = yield signedFetch('getExistingProducts', `${__API_BASE__}/ngi/org/${orgId}/productCredits`, 'GET');

    yield put(getExistingProductsSuccess(response.data));
  } catch (error) {
    yield put(getExistingProductsFailure('Failed to find products'));
  }
}

function* getExistingProductsSaga() {
  yield takeLatest(t.GET_EXISTING_PRODUCTS_REQUEST, getExistingProducts);
}

export default getExistingProductsSaga;
