import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './HubLabel.scss';

function HubLabel({ text = '', PrefixIcon, isCollapsed = false, customClassName = '', ariaLabel = false }) {
  return (
    <div
      className={classnames(
        isCollapsed ? `${styles.flagContainer} ${styles.isCollapsed}` : styles.flagContainer,
        customClassName
      )}
    >
      {PrefixIcon && PrefixIcon}
      <span className={styles.flagTitle} {...(ariaLabel ? { 'aria-label': text } : {})}>
        {text}
      </span>
    </div>
  );
}

HubLabel.propTypes = {
  PrefixIcon: PropTypes.any,
  isCollapsed: PropTypes.bool,
  text: PropTypes.string.isRequired,
  customClassName: PropTypes.string,
  ariaLabel: PropTypes.bool
};

export default HubLabel;
