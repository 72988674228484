import { pick } from 'lodash';
import { put, select, take } from 'redux-saga/effects';
import { getCurrentPlatform, isHubMode } from '../../../../../utils/platform';
import userRoles from '../../../../../globals/userRoles';
import { removeSelectedStudentIds } from '../../../../reducers/classroomPage';
import { removeStudentsFromClassroom as removeStudentsFromClassroomComplete } from '../../../../reducers/data/classrooms';
import { CLEAR_FORM, submissionComplete, SUBMIT_FORM } from '../../../../reducers/removeStudentsFromClassroom';
import editClassroomApi from '../../../apiCalls/editClassroomApi';
import { pollClassStudentsRemoved } from '../dataRecency/pollClassStudents';
import { triggerLoadClassroomEdit } from './edit';
import pickStudentIdsByRole from './pickStudentIdsByRole';

export default function* removeStudentsFromClassroom() {
  console.log('[removeStudentsFromClassroom Saga] Beginning');

  while (true) {
    console.log('[removeStudentsFromClassroom Saga] Waiting for user to submit form');
    yield take(SUBMIT_FORM);

    console.log('[removeStudentsFromClassroom Saga] Form submitted. capturing data from state...');
    const { placementTestSessionId } = yield select(state => ({
      placementTestSessionId: state.placementTestSessionCreate.placementTestSessionId
    }));

    const {
      organisationId,
      classroomId,
      classroomName,
      selectedStudents,
      notifyWithEmail,
      people,
      placementTestPeople
    } = yield select(state => ({
      organisationId: state.classroomPage.orgId || state.identity.currentOrganisationId,
      classroomId: state.classroomPage.classroomId || state.placementTestSessionCreate.placementTestSessionId,
      classroomName:
        state.classrooms.data[state.classroomPage.classroomId]?.name ||
        state.placementTestSessionCreate.placementTestSessionNameValue,
      selectedStudents: state.removeStudentsFromClassroom.submittedIds,
      // eslint-disable-next-line no-nested-ternary
      notifyWithEmail: placementTestSessionId
        ? true
        : isHubMode()
        ? false
        : state.removeStudentsFromClassroom.notifyWithEmail,
      people: state.people.data,
      placementTestPeople: state.removeStudentsFromClassroom.selectedStudentsDetails
    }));
    console.log(
      `[removeStudentsFromClassroom Saga] Submitting request to remove student IDs: ${selectedStudents} from class with ID: ${classroomId}`
    );
    const selectedStudentDetails = !placementTestSessionId ? pick(people, selectedStudents) : placementTestPeople;
    const managedUsersArchived = pickStudentIdsByRole(selectedStudentDetails, userRoles.MANAGED_USER);
    const studentsRemoved = pickStudentIdsByRole(selectedStudentDetails, userRoles.LEARNER);

    const response = yield editClassroomApi(organisationId, classroomId, {
      name: classroomName,
      ...(managedUsersArchived.length ? { managedUsersArchived } : {}),
      ...(studentsRemoved.length ? { studentsRemoved } : {}),
      platformCode: getCurrentPlatform(),
      notifyWithEmail
    });

    const editResult = response || {};
    const requestFailed = editResult.status !== 'success';
    const failedIds = (editResult.data || {}).failedIds || [];

    console.log('[removeStudentsFromClassroom Saga] Edit Class request complete, showing confirmation page');
    yield put(submissionComplete(requestFailed, requestFailed ? selectedStudents : failedIds));

    if (!requestFailed) {
      const successfullyRemovedStudentIds = selectedStudents.filter(id => !failedIds.includes(id));
      yield !placementTestSessionId &&
        put(removeStudentsFromClassroomComplete(classroomId, successfullyRemovedStudentIds));
      yield put(removeSelectedStudentIds(successfullyRemovedStudentIds));
      yield pollClassStudentsRemoved(successfullyRemovedStudentIds);
    }

    yield !placementTestSessionId && triggerLoadClassroomEdit();

    yield take(CLEAR_FORM);
    console.log('[removeStudentsFromClassroom Saga] Form closed, resetting state and saga');
  }
}
