import { pick } from 'lodash';
import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withGetYearGroupOptions from '../language/withGetYearGroupOptions';
import withLocalizedContent from '../language/withLocalizedContent';
import ErrorStatus from '../components/ErrorStatus/ErrorStatus';
import getUsernameParts from '../utils/getUsernameParts';
import PopoutPanelIconHeading, { types } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../components/ScrollContainer/ScrollContainer';
import ManagedUserUpdatePassword from '../components/ManagedUserUpdatePassword/ManagedUserUpdatePassword';
import SidePanel from '../components/SidePanel/SidePanel';
import ManagedUserEditForm from '../components/ManagedUserEditForm/ManagedUserEditForm';
import UserUpdateConfirmation from '../components/UserUpdateConfirmation/UserUpdateConfirmation';
import ErrorSignInCardPanel from './ManagedUserSignInCard/ErrorSignInCardPanel';
import {
  clearManagedUserEditFailure,
  submitEditManagedUser,
  validateManagedUserEditUserFragment,
  validateUserNameGlobal,
  setManagedUserResetPassword,
  triggerBack,
  setStateForEditedField,
  setStateForEditedForm,
  clearStateForEditedField
} from '../redux/actions/managedUserEdit';
import {
  setStudentsToDownload,
  downloadSignInCard,
  clearSignInCardData
} from '../redux/reducers/userSignInCard.reducer';
import getSignInCardGenerationStatus from '../redux/selectors/getSignInCardGenerationStatus';
import DownloadSignInCardPanel from './ManagedUserSignInCard/DownloadSignInCardPanel';
import colors from '../globals/colors';
import Button, { buttonTypes } from '../components/Button/Button';
import { MANAGED_USER_ACCOUNT_STATUS } from '../globals/appConstants';

class ManagedUserEditPanel extends Component {
  _getInput = () => {
    const { user, formState } = this.props;
    const input = ['firstName', 'lastName', 'editableUserName', 'password', 'yearGroup', 'orgUniqueId'];
    return {
      ...pick(user, input),
      ...pick(formState, input)
    };
  };

  _getUpdateFielsName = () => {
    const { formState } = this.props;
    return Object.keys(formState);
  };

  _createChangeHandler = name => value => {
    const { createEditedFieldSetStateHandler, setStateForEditedFormAction } = this.props;
    setStateForEditedFormAction(name, value);

    createEditedFieldSetStateHandler(name, 'onChange');
  };

  _createBlurHandler = name => () => {
    const { formState } = this.props;
    const { user, orgUniqueId, createEditedFieldSetStateHandler, validateUsername, validateUserFragment } = this.props;
    const username = pick(formState, name);
    const { editableUserName: originalUsername } = user;
    const editableUserName = 'editableUserName';
    if (name === editableUserName && username.editableUserName === originalUsername) {
      return; // no need to validate if they are the same
    }
    createEditedFieldSetStateHandler(name, 'onBlur');
    if (name === editableUserName && username.editableUserName) {
      const usernameToBeValidated = `${orgUniqueId}_${username.editableUserName}`;
      validateUsername(usernameToBeValidated);
      validateUserFragment({ username: usernameToBeValidated });
    } else {
      validateUserFragment(pick(formState, name));
    }
  };

  _createFocusHandler = name => () => {
    const { createEditedFieldSetStateHandler } = this.props;
    createEditedFieldSetStateHandler(name, 'onFocus');
  };

  _handleSubmit = () => {
    const { putManagedUserApi, userDetails, resetPassword, submit } = this.props;

    if (putManagedUserApi) {
      const editedData = this._getInput();
      let user;
      const userdtl = {
        ...userDetails,
        firstName: editedData.firstName,
        lastName: editedData.lastName,
        username: `${editedData.orgUniqueId}_${editedData.editableUserName}`,
        password: editedData.password,
        class: userDetails.class ? userDetails.class : '',
        status: resetPassword ? MANAGED_USER_ACCOUNT_STATUS.EXISTS : MANAGED_USER_ACCOUNT_STATUS.NEW
      };
      if (editedData.yearGroup === null) {
        user = userdtl;
      } else {
        user = {
          ...userdtl,
          yearGroup: editedData.yearGroup
        };
      }
      submit(user, this._getUpdateFielsName());
    } else {
      submit(pick(this._getInput(), ['firstName', 'lastName', 'yearGroup']));
    }
  };

  _handleSignInCardComplete = () => {
    const { onClosePanel, onResetPanel } = this.props;
    onResetPanel();
    if (onClosePanel) onClosePanel();
  };

  _onClickDownloadSignInCard = () => {
    const { downloadSignInCardAction } = this.props;
    downloadSignInCardAction();
  };

  _handleDownloadSignInCard = () => {
    const { downloadSignInCard: dsic } = this.props;
    dsic();
  };

  _renderActiveSection = () => {
    const {
      localizedContent: { managedUserEditPanel: content, managedUserSignInCardPanel: signInCardContent },
      user,
      user: { username, isLocked = false },
      organisation,
      orgId,
      getYearGroupOptions,
      errors,
      submitting,
      success,
      failure,
      passwordFailure,
      yearGroupFailure,
      resetPassword,
      clearManagedUserEditFailureAction,
      navigateProfile,
      navigateChangeUsername,
      navigateChangePassword,
      navigateArchive,
      onClosePanel,
      canRemoveUser,
      canOnlyUnlockUser = false,
      userDetails,
      isUserNameTaken,
      restPasswordPanel,
      onBackClick,
      putManagedUserApi,
      userSignInCard: { isDownloadInProgress, isDownloadCompleted, size },
      editedFieldState,
      editedField,
      formState,
      clearStateForEditedFieldAction
    } = this.props;

    switch (true) {
      case submitting:
        return (
          <ScrollContainer>
            <PopoutPanelIconHeading
              type={types.LOADING}
              title={content.saving_heading}
              subtitle={content.saving_subheading}
            />
          </ScrollContainer>
        );
      case isDownloadInProgress:
        return (
          <ScrollContainer>
            <PopoutPanelIconHeading
              type={types.LOADING}
              title={content.saving_heading}
              subtitle={content.creating_signin_card}
            />
          </ScrollContainer>
        );
      case isDownloadCompleted:
        return (
          <DownloadSignInCardPanel
            content={signInCardContent}
            onDone={this._handleSignInCardComplete}
            size={size}
            onDownload={this._onClickDownloadSignInCard}
          />
        );
      case success:
        return (
          <UserUpdateConfirmation
            title={content.success_heading}
            subtitle={content.success_subheading}
            color={colors.LEARNER}
            actions={[
              {
                text: content.create_signin_card_text,
                action: this._handleDownloadSignInCard
              },
              {
                text: content.button_return_to_profile,
                action: navigateProfile
              }
            ]}
            onDone={onClosePanel}
            users={[
              {
                username: content.details_updated,
                ...this._getInput()
              }
            ]}
          />
        );
      case failure:
        return (
          <ScrollContainer>
            <ErrorStatus
              title={content.error_title}
              buttonText={content.error_retry_button}
              buttonOnClickHandler={clearManagedUserEditFailureAction}
            />
          </ScrollContainer>
        );
      case passwordFailure:
        return (
          <ScrollContainer>
            <ErrorStatus
              title={content.error_password_title}
              subtitle={content.error_password_subtitle}
              buttonText={content.button_return_to_profile}
              buttonOnClickHandler={navigateProfile}
              isPasswordFailure={passwordFailure}
              buttonFullWidth={passwordFailure}
            />
          </ScrollContainer>
        );
      case yearGroupFailure:
        return (
          <ScrollContainer>
            <ErrorSignInCardPanel
              errorMsg={yearGroupFailure}
              content={content}
              onDone={this._handleSignInCardComplete}
            />
          </ScrollContainer>
        );
      case resetPassword:
        return (
          <ManagedUserUpdatePassword
            firstName={user.firstName}
            lastName={user.lastName}
            username={getUsernameParts(username || '').username}
            password={formState.password || ''}
            errors={errors}
            backAction={onBackClick}
            onSubmit={this._handleSubmit}
            createChangeHandler={this._createChangeHandler}
            orgId={orgId}
          />
        );
      default:
        return (
          <ManagedUserEditForm
            user={user}
            getInput={this._getInput()}
            username={getUsernameParts(username || '').username}
            yearGroups={getYearGroupOptions(organisation.curriculumType)}
            userLocked={isLocked}
            errors={errors}
            onChangeUsernameClick={navigateChangeUsername}
            onChangePasswordClick={navigateChangePassword}
            onRemoveClick={navigateArchive}
            createChangeHandler={this._createChangeHandler}
            createBlurHandler={this._createBlurHandler}
            createFocusHandler={this._createFocusHandler}
            onSubmit={this._handleSubmit}
            canRemoveUser={canRemoveUser}
            canOnlyChangePassword={canOnlyUnlockUser}
            userDetails={userDetails}
            isUserNameTaken={isUserNameTaken}
            restPasswordPanel={restPasswordPanel}
            putManagedUserApi={putManagedUserApi}
            downloadSignInCard={this._handleDownloadSignInCard}
            editedFieldState={editedFieldState}
            editedField={editedField}
            formState={formState}
            clearStateForEditedFieldAction={clearStateForEditedFieldAction}
          />
        );
    }
  };

  render() {
    const {
      localizedContent: { managedUserEditPanel: content },
      open,
      onClosePanel,
      userSignInCard: { isDownloadCompleted },
      putManagedUserApi
    } = this.props;

    return (
      <SidePanel
        id="managedUserEditPanel"
        ariaLabel="Edit managed user"
        isOpen={open}
        onClose={isDownloadCompleted ? this._handleSignInCardComplete : onClosePanel}
        panelCloseComponent={
          putManagedUserApi ? (
            <Button
              type={buttonTypes.CLOSE_BOLD_HOVER}
              onClick={isDownloadCompleted ? this._handleSignInCardComplete : onClosePanel}
              text={content.close}
            />
          ) : (
            undefined
          )
        }
      >
        {this._renderActiveSection()}
      </SidePanel>
    );
  }
}

ManagedUserEditPanel.propTypes = {
  orgId: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    editableUserName: PropTypes.string,
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    yearGroup: PropTypes.number,
    class: PropTypes.string,
    isLocked: PropTypes.bool
  }).isRequired,
  organisation: PropTypes.shape({
    curriculumType: PropTypes.string.isRequired
  }).isRequired,
  getYearGroupOptions: PropTypes.func.isRequired,
  validateUserFragment: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  errors: PropTypes.array,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  passwordFailure: PropTypes.bool.isRequired,
  yearGroupFailure: PropTypes.bool.isRequired,
  resetPassword: PropTypes.bool.isRequired,
  clearManagedUserEditFailureAction: PropTypes.func.isRequired,
  navigateChangeUsername: PropTypes.func.isRequired,
  navigateChangePassword: PropTypes.func.isRequired,
  navigateArchive: PropTypes.func.isRequired,
  navigateProfile: PropTypes.func.isRequired,
  onClosePanel: PropTypes.func.isRequired,
  canRemoveUser: PropTypes.bool.isRequired,
  canOnlyUnlockUser: PropTypes.bool,
  userDetails: PropTypes.object,
  validateUsername: PropTypes.func.isRequired,
  orgUniqueId: PropTypes.string,
  isUserNameTaken: PropTypes.bool,
  restPasswordPanel: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  putManagedUserApi: PropTypes.bool,
  userSignInCard: PropTypes.object,
  downloadSignInCard: PropTypes.func,
  downloadSignInCardAction: PropTypes.func,
  onResetPanel: PropTypes.func,
  createEditedFieldSetStateHandler: PropTypes.func,
  editedFieldState: PropTypes.string,
  editedField: PropTypes.string,
  formState: PropTypes.object,
  clearStateForEditedFieldAction: PropTypes.func,
  setStateForEditedFormAction: PropTypes.func
};

export default compose(
  withRouter,
  withLocalizedContent('managedUserEditPanel', 'managedUserSignInCardPanel'),
  withGetYearGroupOptions,
  connect(
    (state, { orgId }) => ({
      ...state.managedUserEdit,
      organisation: state.organisations.data[orgId],
      userSignInCard: getSignInCardGenerationStatus(state)
    }),
    (dispatch, { orgId, userId, history }) => ({
      validateUserFragment: fragment => {
        dispatch(validateManagedUserEditUserFragment(fragment));
      },
      validateUsername: userName => {
        dispatch(validateUserNameGlobal(userName));
      },
      submit: (user, fields) => {
        dispatch(submitEditManagedUser(orgId, userId, user, fields));
      },
      clearManagedUserEditFailureAction: () => {
        dispatch(clearManagedUserEditFailure());
      },
      navigateProfile: () => {
        history.push(`/org/${orgId}/user/${userId}`);
      },
      restPasswordPanel: () => {
        dispatch(setManagedUserResetPassword());
      },
      onBackClick: () => {
        dispatch(triggerBack());
      },
      downloadSignInCard: () => {
        dispatch(setStudentsToDownload(userId));
      },
      downloadSignInCardAction: () => {
        dispatch(downloadSignInCard());
      },
      onResetPanel: () => {
        dispatch(clearSignInCardData());
      },
      createEditedFieldSetStateHandler: (field, fieldState) => {
        dispatch(setStateForEditedField(field, fieldState));
      },
      setStateForEditedFormAction: (field, fieldState) => {
        dispatch(setStateForEditedForm(field, fieldState));
      },
      clearStateForEditedFieldAction: () => {
        dispatch(clearStateForEditedField());
      }
    })
  )
)(ManagedUserEditPanel);
