// eslint-disable-next-line import/prefer-default-export
export const standardSortOptions = currentSort => [
  {
    text: 'Name (A-Z)',
    id: 'searchOrder-by-name-ASC',
    name: 'searchOrder',
    value: 'name:asc',
    checked: currentSort === 'name:asc'
  },
  {
    text: 'Name (Z-A)',
    id: 'searchOrder-by-name-DESC',
    name: 'searchOrder',
    value: 'name:desc',
    checked: currentSort === 'name:desc'
  },
  {
    text: 'Newest First',
    id: 'searchOrder-by-createdDate-DESC',
    name: 'searchOrder',
    value: 'date:desc',
    checked: currentSort === 'date:desc'
  },
  {
    text: 'Oldest First',
    id: 'searchOrder-by-createdDate-ASC',
    name: 'searchOrder',
    value: 'date:asc',
    checked: currentSort === 'date:asc'
  }
];
export const searchUserSortOptions = (idPrefix, sort) => [
  {
    text: 'First Name (A-Z)',
    id: `${idPrefix}-by-firstName-ASC`,
    name: 'searchOrder',
    value: 'firstName:asc',
    checked: sort === 'firstName:asc'
  },
  {
    text: 'First Name (Z-A)',
    id: `${idPrefix}-by-firstName-DESC`,
    name: 'searchOrder',
    value: 'firstName:desc',
    checked: sort === 'firstName:desc'
  },
  {
    text: 'Last Name (A-Z)',
    id: `${idPrefix}-by-lastName-ASC`,
    name: 'searchOrder',
    value: 'lastName:asc',
    checked: sort === 'lastName:asc'
  },
  {
    text: 'Last Name (Z-A)',
    id: `${idPrefix}-by-lastName-DESC`,
    name: 'searchOrder',
    value: 'lastName:desc',
    checked: sort === 'lastName:desc'
  },
  {
    text: 'Newest First',
    id: `${idPrefix}-by-date-DESC`,
    name: 'searchOrder',
    value: 'date:desc',
    checked: sort === 'date:desc'
  },
  {
    text: 'Oldest First',
    id: `${idPrefix}-by-date-ASC`,
    name: 'searchOrder',
    value: 'date:asc',
    checked: sort === 'date:asc'
  }
];

export const searchAllUserSortOptions = (idPrefix, sort) => {
  const baseSearchOptions = searchUserSortOptions(idPrefix, sort);

  baseSearchOptions.splice(
    4,
    0,
    {
      text: 'Organization Name (A-Z)',
      id: `${idPrefix}-by-organisationName-ASC`,
      name: 'searchOrder',
      value: 'organisationName:asc',
      checked: sort === 'organisationName:asc'
    },
    {
      text: 'Organisation Name (Z-A)',
      id: `${idPrefix}-by-organisationName-DESC`,
      name: 'searchOrder',
      value: 'organisationName:desc',
      checked: sort === 'organisationName:desc'
    },
    {
      text: 'Country (A-Z)',
      id: `${idPrefix}-by-country-ASC`,
      name: 'searchOrder',
      value: 'country:asc',
      checked: sort === 'country:asc'
    },
    {
      text: 'Country (Z-A)',
      id: `${idPrefix}-by-country-DESC`,
      name: 'searchOrder',
      value: 'country:desc',
      checked: sort === 'country:desc'
    }
  );

  return baseSearchOptions;
};

export const searchStudentSortOptions = (idPrefix, sort, hideYearGroup) => [
  ...searchUserSortOptions(idPrefix, sort),
  ...(hideYearGroup
    ? []
    : [
        {
          text: 'Year Group',
          id: `${idPrefix}-by-yearGroup-ASC`,
          name: 'searchOrder',
          value: 'yearGroup:asc',
          checked: sort === 'yearGroup:asc'
        }
      ])
];

// TODO: Consider replacing this with standardSortOptions:
export const searchProductSortOptions = (idPrefix, sort) => [
  {
    text: 'Name (A-Z)',
    id: `${idPrefix}-by-name-ASC`,
    name: 'sortProducts',
    value: 'name:asc',
    checked: sort === 'name:asc'
  },
  {
    text: 'Name (Z-A)',
    id: `${idPrefix}-by-name-DESC`,
    name: 'sortProducts',
    value: 'name:desc',
    checked: sort === 'name:desc'
  }
];

export const searchPlacementStudentsSortOptions = currentSort => [
  {
    text: 'FirstName (A-Z)',
    id: 'searchPlacementStudent-by-firstName-ASC',
    name: 'searchPlacementStudents',
    value: 'firstName:asc',
    checked: currentSort === 'firstName:asc'
  },
  {
    text: 'FirstName (Z-A)',
    id: 'searchPlacementStudent-by-firstName-DESC',
    name: 'searchPlacementStudents',
    value: 'firstName:desc',
    checked: currentSort === 'firstName:desc'
  },
  {
    text: 'LastName (A-Z)',
    id: 'searchPlacementStudent-by-lastName-ASC',
    name: 'searchPlacementStudents',
    value: 'lastName:asc',
    checked: currentSort === 'lastName:asc'
  },
  {
    text: 'LastName (Z-A)',
    id: 'searchPlacementStudent-by-lastName-DESC',
    name: 'searchPlacementStudents',
    value: 'lastName:desc',
    checked: currentSort === 'lastName:desc'
  },
  {
    text: 'Newest',
    id: 'searchPlacementStudent-by-createdDate-DESC',
    name: 'searchPlacementStudents',
    value: 'date:desc',
    checked: currentSort === 'date:desc'
  },
  {
    text: 'Oldest',
    id: 'searchPlacementStudent-by-createdDate-ASC',
    name: 'searchPlacementStudents',
    value: 'date:asc',
    checked: currentSort === 'date:asc'
  }
];
