import * as t from '../../actionTypes';

const requestStatusOptions = { success: [], isLoading: true, error: false };
const successStatusOptions = { success: [], isLoading: false, error: false };
const failureStatusOptions = { success: [], isLoading: false, error: true };

const initialState = {
  isLoading: false,
  error: false,
  success: []
};

function existingProducts(state = initialState, action) {
  switch (action.type) {
    case t.GET_EXISTING_PRODUCTS_REQUEST:
      return {
        ...state,
        ...requestStatusOptions,
        error: false
      };
    case t.GET_EXISTING_PRODUCTS_SUCCESS:
      return { ...state, ...successStatusOptions, success: action.payload, error: action.payload.length === 0 };
    case t.GET_EXISTING_PRODUCTS_FAILURE:
      return { ...state, ...failureStatusOptions, error: true, success: [] };
    default:
      return state;
  }
}

export default existingProducts;
