import { put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes.js';
import {
  addProductCreditsFailure,
  addProductCreditsSuccess,
  getProductCreditsFailure,
  getProductCreditsSuccess
} from '../../../../actions/productCreditsActions.js';
import signedFetch from '../../../apiCalls/util/signedFetch.js';

function* getProductCredits(action) {
  const { orgId, productId } = action.payload;

  try {
    const response = yield signedFetch(
      'ngiFindCredits',
      `${__API_BASE__}/ngi/org/${orgId}/credits/${productId}`,
      'GET'
    );

    const responsePayload = {
      credits: response.credits || 0,
      productId: response.productId || productId
    };

    yield put(getProductCreditsSuccess(responsePayload));
  } catch (error) {
    yield put(getProductCreditsFailure('Failed to find credits'));
  }
}

export function* getProductCreditsSaga() {
  yield takeLatest(t.GET_PRODUCT_CREDITS_REQUEST, getProductCredits);
}

function* addProductCredits(action) {
  const { orgId, productId, amount } = action.payload;

  try {
    const response = yield signedFetch(
      'ngiAddCredits',
      `${__API_BASE__}/ngi/org/${orgId}/credits/${productId}`,
      'POST',
      JSON.stringify({
        amount: parseInt(amount, 10)
      })
    );

    const responsePayload = {
      credits: typeof response.credits === 'undefined' ? amount : response.credits,
      productId: response.productId || productId
    };

    yield put(addProductCreditsSuccess(responsePayload));
  } catch (error) {
    yield put(addProductCreditsFailure('Failed to add credits'));
  }
}

export function* addProductCreditsSaga() {
  yield takeLatest(t.ADD_PRODUCT_CREDITS_REQUEST, addProductCredits);
}
