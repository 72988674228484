import signedFetch from './util/signedFetch.js';

export default (classId, page = 0, limit = 10, sortBy = 'firstName:asc') =>
  signedFetch(
    'getStudentsInTestSession',
    `${__API_BASE__}/placementTest/${encodeURIComponent(
      classId
    )}/students?page=${page}&limit=${limit}&sortBy=${sortBy}`,
    'GET'
  );
