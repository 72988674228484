/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import React from 'react';
import Button, { buttonTypes } from '../../../components/Button/Button.js';
import ControlledForm from '../../../components/ControlledForm/ControlledForm.js';
import DateField from '../../../components/DateField/DateField.js';
import withLocalizedContent from '../../../language/withLocalizedContent.js';
import PageHeading from '../../../components/PageHeading/PageHeading.js';

import ValidationStatus from '../../../components/ValidationStatus/ValidationStatus.js';
import styles from './InvoiceReport.scss';

function InvoiceReportForm({
  localizedContent,
  startDate = '',
  endDate = '',
  errors = {},
  onChangeHandler = () => {},
  onBlurHandler = () => {},
  onSubmit = () => {}
}) {
  return (
    <ControlledForm>
      <div className={styles.invoiceReport}>
        <div className={styles.title}>
          <PageHeading title={localizedContent.page_title} />
          <p>{localizedContent.time_limit_text}</p>
          {errors && errors.message && <h3 className={styles.error}>{errors.message}</h3>}
        </div>
        <div className="col">
          <div className={styles.dateFields}>
            <ValidationStatus
              forId="startDate"
              containerClassName={styles.field}
              isActive={errors.startDate}
              onClick={onBlurHandler('startDate')}
            >
              <DateField
                id="startDate"
                value={startDate}
                label={localizedContent.start_date_label}
                placeholder={localizedContent.start_date_placeholder}
                displayDateFormat="dd/MM/yyyy"
                max={new Date()}
                onChange={onChangeHandler('startDate')}
              />
            </ValidationStatus>
            <ValidationStatus
              forId="endDate"
              containerClassName={styles.dateFields}
              isActive={errors.endDate}
              onClick={onBlurHandler('endDate')}
            >
              <DateField
                id="endDate"
                name="endDate"
                value={endDate}
                label={localizedContent.end_date_label}
                placeholder={localizedContent.end_date_placeholder}
                displayDateFormat="dd/MM/yyyy"
                onChange={onChangeHandler('endDate')}
                max={new Date()}
              />
            </ValidationStatus>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <span className="gin-right2">
              <Button type={buttonTypes.PRIMARY} text={localizedContent.form_submit_button} onClick={onSubmit} />
            </span>
          </div>
        </div>
      </div>
    </ControlledForm>
  );
}

InvoiceReportForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  errors: PropTypes.object,
  onChangeHandler: PropTypes.func,
  onBlurHandler: PropTypes.func,
  onSubmit: PropTypes.func
};

export default withLocalizedContent('invoiceReportForm')(InvoiceReportForm);
