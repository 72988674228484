import * as t from '../actionTypes.js';

export const getExistingProductsRequest = (params = {}) => ({
  type: t.GET_EXISTING_PRODUCTS_REQUEST,
  payload: params
});

export const getExistingProductsSuccess = products => ({
  type: t.GET_EXISTING_PRODUCTS_SUCCESS,
  payload: products
});

export const getExistingProductsFailure = error => ({
  type: t.GET_EXISTING_PRODUCTS_FAILURE,
  payload: error
});
