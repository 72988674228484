const viewProductInformationAction = (content, toggleDetails) => ({
  title: content.org_licences_view_product_info_text,
  action: toggleDetails
});
const getCourseMaterialActionButtons = (
  content,
  { toggleDetails = null, downloadProduct = null },
  { showDownloadProductOption = false }
) => [
  viewProductInformationAction(content, toggleDetails),
  showDownloadProductOption ? { title: content.download_product, action: downloadProduct } : {}
];

export default getCourseMaterialActionButtons;
