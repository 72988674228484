// Page load and clear actions
export const CLEAR_FORM = 'removeStudentsFromClassroom/CLEAR_FORM';

const SET_USERS = 'removeStudentsFromClassroom/SET_USERS';

const SET_SELECTED_STUDENTS = 'removeStudentsFromClassRoom/SET_SELECTED_STUDENTS';
// Update email choice
const SET_NOTIFY_WITH_EMAIL = 'removeStudentsFromClassroom/SET_NOTIFY_WITH_EMAIL';

// Nav actions
export const SUBMIT_FORM = 'removeStudentsFromClassroom/SUBMIT_FORM';
const SUBMISSION_COMPLETE = 'removeStudentsFromClassroom/SUBMISSION_COMPLETE';

export const formStates = {
  REVIEW_SELECTIONS: 'REVIEW_SELECTIONS',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION' // Success|Fail feedback after SUBMITTING
};

const initialState = {
  formState: formStates.REVIEW_SELECTIONS,

  // Selected users and the toggle to allow sending an email to them
  notifyWithEmail: false,

  // List submitted for removal
  submittedIds: [],
  // List of users who were not removed from the class after submission
  failedIds: [],

  // General error catch all
  requestFailed: null,

  // List of ids of the students who was selected to be remove from a placement test
  selectedStudentsIds: [],

  // Details of the students who was selected to be remove from a placement test
  selectedStudentsDetails: {}
};

export default function removeStudentsFromClassroom(state = initialState, action = {}) {
  switch (action.type) {
    // Page load and clear actions
    case CLEAR_FORM:
      return {
        ...initialState
      };

    // Set users to remove
    case SET_USERS:
      return {
        ...state,
        submittedIds: action.submittedIds
      };

    case SET_SELECTED_STUDENTS:
      return {
        ...state,
        selectedStudentsIds: [...action.selectedStudentsIds],
        selectedStudentsDetails: { ...action.selectedStudentsDetails }
      };

    // Update email choice
    case SET_NOTIFY_WITH_EMAIL:
      return {
        ...state,
        notifyWithEmail: action.notifyWithEmail
      };

    // Nav actions
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SUBMISSION_COMPLETE:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        requestFailed: action.requestFailed,
        failedIds: action.failedIds
      };
    default:
      return state;
  }
}

// Page load and clear actions
export const clearForm = () => ({
  type: CLEAR_FORM
});

export const setUsers = submittedIds => ({
  type: SET_USERS,
  submittedIds
});

export const setSelectedStudents = (selectedStudentsIds, selectedStudentsDetails) => ({
  type: SET_SELECTED_STUDENTS,
  selectedStudentsIds,
  selectedStudentsDetails
});

// Update email choice
export const setNotifyWithEmail = notifyWithEmail => ({
  type: SET_NOTIFY_WITH_EMAIL,
  notifyWithEmail
});

// Nav actions
export const submitForm = () => ({
  type: SUBMIT_FORM
});
export const submissionComplete = (requestFailed, failedIds) => ({
  type: SUBMISSION_COMPLETE,
  requestFailed,
  failedIds
});
