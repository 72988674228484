import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Components
import DropdownPanel from './DropdownPanel/DropdownPanel';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
// Styles
import styles from './HubDropdownMenu.scss';

class HubDropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownToggled: false
    };
  }

  componentDidUpdate(prevProps) {
    const { hideDropdown } = this.props;
    const { hideDropdown: prevHideDropdown } = prevProps;

    if (hideDropdown !== prevHideDropdown && hideDropdown === true) {
      this.setState({ isDropdownToggled: false });
    }
  }

  toggleDropdown = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState(state => ({ isDropdownToggled: !state.isDropdownToggled }));
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.toggleDropdown(e);
    }
  };

  closeDropdown = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState(() => ({ isDropdownToggled: false }));
  };

  render() {
    const {
      parentId,
      customClassname,
      panelCustomClassname,
      dropdownData: { menuItems = [], menuPanelAriaLabel, menuButtonAriaLabel } = {},
      dropdownTop,
      tabindex,
      isAssessmentTestComponent,
      isPlacementTests,
      icon = undefined
    } = this.props;
    const { isDropdownToggled } = this.state;

    return (
      <div className={classnames([styles.hubDropdownMenu], customClassname)}>
        <button
          data-testid={`HUB_DROPDOWN_MENU_OPEN_${parentId}`}
          type="button"
          id={`${parentId}-dropdownButton`}
          className={classnames([styles.dropdownButton], { [styles.selected]: isDropdownToggled })}
          onClick={this.toggleDropdown}
          onKeyDown={this.handleKeyDown}
          tabIndex={tabindex}
          aria-label={`${menuButtonAriaLabel}`}
        >
          <div className={styles.svgContainer}>{icon || <SVGIcon glyph={GLYPHS.ICON_MENU_STACKED} />}</div>
        </button>

        <DropdownPanel
          menuItems={menuItems}
          onToggleDropdown={this.closeDropdown}
          toggled={isDropdownToggled}
          menuPanelId={`${parentId}-dropdownPanel`}
          menuButtonId={`${parentId}-dropdownButton`}
          menuPanelAriaLabel={menuPanelAriaLabel}
          dropdownTop={dropdownTop}
          panelCustomClassname={panelCustomClassname}
          isAssessmentTestComponent={isAssessmentTestComponent}
          isPlacementTests={isPlacementTests}
        />
      </div>
    );
  }
}

export default HubDropdownMenu;

HubDropdownMenu.propTypes = {
  parentId: PropTypes.string.isRequired,
  dropdownData: PropTypes.object.isRequired,
  customClassname: PropTypes.string,
  panelCustomClassname: PropTypes.string,
  dropdownTop: PropTypes.bool,
  tabindex: PropTypes.number,
  isAssessmentTestComponent: PropTypes.bool,
  isPlacementTests: PropTypes.bool,
  icon: PropTypes.object,
  hideDropdown: PropTypes.bool
};
