import { put } from 'redux-saga/effects';

import { getStudentsInTestSessionSuccess, getStudentsInTestSessionFailure } from '../../../../actions/placementTests';
import getStudentsInTestSessionApi from '../../../apiCalls/getStudentsInTestSessionApi';

function* getStudentsInSession({ payload }) {
  const { classId, page, limit, sortBy } = payload;
  const response = yield getStudentsInTestSessionApi(classId, page, limit, sortBy);

  if (response.status === 'success') {
    yield put(getStudentsInTestSessionSuccess(response.data));
  } else {
    yield put(getStudentsInTestSessionFailure(response.message));
  }
}

export default getStudentsInSession;
