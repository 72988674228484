import { select, put } from 'redux-saga/effects';
import { lowerFirst } from 'lodash';
import Papa from 'papaparse';
import { orgRoles } from '@oup/shared-node-browser/org.js';
import { ORG_STAFF, ORG_STUDENTS } from '@oup/shared-node-browser/constants.js';
import sanitizeCSV from '../../../../../../utils/sanitizeCSV.js';
import { ErrorTypes } from '../../../../../../components/ImportUsers/ImportUsersErrorStatus';
import { showInputErrorReview, showDataReview } from '../../../../../reducers/enrolUser.reducer.js';

export const MAX_DATA_ROWS = 1000;

export default function* bulkReviewableUploadRequest() {
  console.log('[bulkReviewableUpload Saga] Uploading file contents...');
  const {
    orgId,
    orgRole,
    userTypeToEnrol,
    file: { data: fileContents, name: fileName, type: fileType }
  } = yield select(state => state.enrolUser);

  console.info(orgId, orgRole, userTypeToEnrol, ORG_STUDENTS, fileContents, fileName, fileType);

  // -- COLUMN_HEADINGS map based on orgRole and userTypeToEnrol
  const COLUMN_HEADINGS =
    {
      [orgRoles.SECONDARY_SCHOOL]: {
        [ORG_STUDENTS]: ['UserName', 'FirstName', 'LastName', 'EmailAddress', 'RoleName', 'Class'],
        [ORG_STAFF]: []
      }
    }[orgRole][userTypeToEnrol] || [];

  const {
    data,
    meta: { fields }
  } = Papa.parse(sanitizeCSV(fileContents, lowerFirst, COLUMN_HEADINGS), {
    header: true,
    skipEmptyLines: true
  });

  const missingFields = COLUMN_HEADINGS.filter(key => !fields.includes(key));

  if (missingFields.length > 0) {
    return yield put(showInputErrorReview(ErrorTypes.COLUMN_HEADINGS, missingFields, MAX_DATA_ROWS));
  }

  if (data.length > MAX_DATA_ROWS) {
    return yield put(showInputErrorReview(ErrorTypes.TOO_MANY_ROWS, missingFields, MAX_DATA_ROWS));
  }

  // 1. show structure errors client side validation
  // 2. show data review screen - showErrorReview()
  // 3. make api call - bulkUserUploadApi() and others
  // 4. show confirmation screen - bulkShowConfirmation()

  return yield put(showDataReview());
}
